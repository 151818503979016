import React from 'react';
import { useTranslation } from 'next-i18next';
import { convertLangCodeForDB } from '@lib';
import { Card, Title } from '@components/common';
import { FlexWrap } from '@components/wrapper';
import { Contents } from '@components/layout';
import { CATEGORY_SNO, LanguageCode } from 'src/constants';
import { PATH_PRODUCT } from 'src/routes/paths';
import useExchange from '@hooks/useExchange';
import { LanguageCodeEnum, ProductCardsWithBrandQuery, useMainProductListV2Query, useProductCardsWithBrandQuery } from 'src/generated/graphql';
import { discountAmount } from '@lib/price';
import { productClickEventLog } from '@lib/mixpanel/events';
import { ICardClickProps, ProductCardType } from '../Card/type';
import { CardGroupSkeleton, CardPromotionContainer, CardSkeleton } from './styled';

type Props = {
  sno: number;
  subTitle?: string;
  subTitleColor?: string;
  subTitleTextColor?: string;
  eventPageName?: string;
  titleTextColor?: string;
  bgColor?: string;
};

export const CardPromotionGroup: React.FC<Props> = ({ sno, subTitle, subTitleColor, subTitleTextColor, titleTextColor, bgColor, eventPageName }) => {
  const { i18n } = useTranslation('common');
  const { isReady } = useExchange(i18n.language as LanguageCode);

  const { data, loading, error } = useMainProductListV2Query({
    variables: {
      sno,
      lang: convertLangCodeForDB(i18n.language),
    },
  });
  const dataObj = isReady && !loading && !error && data && data.mainProductListV2;
  const productNos = dataObj?.productList || [];

  const { data: productCardsData } = useProductCardsWithBrandQuery({
    variables: {
      productNos: productNos,
      languageCode: i18n.language as LanguageCodeEnum,
    },
    skip: productNos.length === 0,
  });

  // [todo] : 현재 카드 데이터 형태에 맞게 수정,
  // 추후 Card를 사용하는 모든 영역 데이터가 현재와 같을 경우 Card 컴포넌트 수정되어야 함.
  const convertProductInfo = (productInfo: ProductCardsWithBrandQuery['productCards'][0]) => {
    const goodsNo = productInfo.id.toString();

    return {
      ...productInfo,
      productViewUrl: PATH_PRODUCT.view(goodsNo, sno, dataObj?.title),
      goodsNo,
      brandCd: productInfo.brand.code,
      brandName: productInfo.brand.name,
      title: productInfo.title,
      reviewCount: productInfo.reviewCount,
      viewCount: productInfo.viewCount,
      badgeName: productInfo.badgeNames.length > 0 ? productInfo.badgeNames[0] : '',
      languageCode: i18n.language as LanguageCode,
      price: productInfo.salePrice,
      discountPercentage: discountAmount(productInfo.price, productInfo.salePrice),
      subImageUrl: productInfo.mainImageUrl,
      imageUrl: productInfo.subImageUrl,
      isGoods: true,
      extensions: productInfo.extensions.map((extension) => extension),
      isPromotion: Boolean(productInfo.promotionEndDate),
    };
  };

  const handleCardClick = ({ actionName, goodsNo, title }: ICardClickProps, order: number) => {
    if (!eventPageName) return;
    productClickEventLog({
      pageName: eventPageName,
      actionName: actionName,
      productName: title,
      productNo: goodsNo,
      groupSno: sno,
      groupTitle: dataObj?.title,
      order: order,
    });
  };

  const productList = (productCardsData?.productCards || []).map((goodsInfo) => convertProductInfo(goodsInfo));

  return (
    <CardPromotionContainer bgColor={bgColor}>
      <Contents marginTop={0} {...(sno === CATEGORY_SNO.NEW && { marginBottom: 40 })}>
        {dataObj ? (
          <>
            <Title
              titleTextColor={titleTextColor}
              subTitle={subTitle}
              subTitleColor={subTitleColor}
              subTitleTextColor={subTitleTextColor}
              style={{
                marginBottom: '16px',
                border: 'none',
              }}
            >
              {dataObj.title}
            </Title>
            <FlexWrap className={'gap-x-9'}>
              {productList.map((goodsInfo: ProductCardType, idx: number) => (
                <Card
                  {...goodsInfo}
                  key={idx}
                  productViewUrl={PATH_PRODUCT.view(goodsInfo.goodsNo, sno, dataObj?.title)}
                  {...(sno === CATEGORY_SNO.NEW && {
                    isBorderRound: true,
                  })}
                  onClick={(props) => handleCardClick(props, idx + 1)}
                />
              ))}
            </FlexWrap>
          </>
        ) : (
          <div>
            <Title isSkeleton={true} isView={false} />
            <CardGroupSkeleton>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </CardGroupSkeleton>
          </div>
        )}
      </Contents>
    </CardPromotionContainer>
  );
};

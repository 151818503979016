import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { sha256 } from 'js-sha256';
import { useUserInfo } from '@hooks';
import { BandBanner } from '@components/header/BandBanner';
import { GET_HEADER_INFO } from '@api';
import { Gnb } from '@components/header/gnb';
import useSettings from '@hooks/useSettings';
import { PAGE_TYPE } from 'src/constants';
import { CommonContext, UserInfoStatusEnum } from '../../src/provider';
import UnsupportedBrowser from './UnsupportedBrowser';
import { SubGnb } from './subGnb';
import { Container, Wrapper } from './styled';

const GNB_HEIGHT = 70;

const BAND_BANNER_COOKIE_KEY = 'BAND_BANNER';
const GA_ACON_USER_ID_COOKIE_KEY = 'ga_acon_user_id';

export const Header: React.FC = () => {
  // 상단 gnb 에 대한 top
  const [top, setTop] = useState(0);
  // 번역도구
  const { i18n } = useTranslation('common');
  // 쿠키들
  const [cookies, setCookie, removeCookie] = useCookies();
  const { userInfo, status } = useUserInfo();
  const { clips, carts, cartsOnSession } = useContext(CommonContext);

  const cartsCount = useMemo(() => (status === UserInfoStatusEnum.authenticated ? carts : cartsOnSession)?.length, [status, carts, cartsOnSession]);

  const [isShowExplore, setIsShowExplore] = useState(false);
  const [hasMounted, setHasmounted] = useState(false);
  const { themeMode } = useSettings();

  // 쿠키에 저장된 배너 숨김 정보
  const bannerCookie = cookies[BAND_BANNER_COOKIE_KEY] || {};

  let queryLanguage = i18n.language;
  if (queryLanguage === 'zh') queryLanguage = 'cn';
  if (queryLanguage === 'ja') queryLanguage = 'jp';

  // 밴드 배너 데이터 조회
  const { data: headerInfoItem } = useQuery(GET_HEADER_INFO, { variables: { lang: queryLanguage } });
  // 배너 데이터
  const bandBannerData = headerInfoItem?.getBandBannerForPage?.filter((e) => {
    const hideUntil = bannerCookie[e.id]?.hideUntil;
    return !hideUntil || hideUntil < Date.now();
  });

  const bannerHeight = (hasMounted ? bandBannerData?.length || 0 : 0) * 50;
  const exploreHeight = isShowExplore ? 9999 : 0;
  const maxHeightToHide = bannerHeight + GNB_HEIGHT + exploreHeight;
  const isStickySubGNB = -top === maxHeightToHide;

  useEffect(() => {
    // mount 이후에 렌더되는 요소들은 build 과정에서 미리 렌더되지 않으므로
    // 이를 구분하기 위해 다음과 같이 처리해줍니다.
    setHasmounted(true);
  }, []);

  // kakao
  const isKakaoScriptLoad = typeof window !== 'undefined' && Boolean(window.Kakao);
  useEffect(() => {
    const jsKey = '5b58fea3856a0c411926e0340a61626d';

    // SDK는 한 번만 초기화해야 한다.
    // 중복되는 초기화를 막기 위해 isInitialized()로 SDK 초기화 여부를 판단한다.
    if (isKakaoScriptLoad && !window.Kakao?.isInitialized()) {
      // JavaScript key를 인자로 주고 SDK 초기화
      window.Kakao.init(jsKey);
    }
  }, [isKakaoScriptLoad]);

  // 파라미터에 배너 id를 입력하면 해당 id의 쿠키를 저장하는 callback을 리턴합니다.
  const genRemoveCallbackByBannerId = (id) => {
    // 밴드 배너 항목을 삭제하는 이벤트 처리기 메소드입니다.
    return (e) => {
      e.preventDefault();
      // 쿠키 만료일자
      const expiry = new Date();
      // 쿠키 만료일자를 오늘일자 YYYY-MM-DDT23:59:59 로 세팅
      expiry.setHours(23, 59, 59, 999);

      bannerCookie[id] = { hideUntil: expiry };
      setCookie(BAND_BANNER_COOKIE_KEY, bannerCookie, { path: '/' });
    };
  };

  // 현재 스크롤 Y 값을 가져오는 메소드입니다.
  const getScrollY = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  useEffect(() => {
    if (userInfo) {
      datadogRum.setUser({
        id: userInfo.userId,
      });
      // GTM 에서 사용하는 유저 식별 정보 쿠키 설정
      setCookie(GA_ACON_USER_ID_COOKIE_KEY, sha256(userInfo.userId), { path: '/' });
    } else {
      // GTM 에서 사용하는 유저 식별 정보 쿠키 삭제 (유저 정보가 없으면 로그인 상태가 아닌 걸로 간주)
      removeCookie(GA_ACON_USER_ID_COOKIE_KEY, { path: '/' });
    }
  }, [userInfo?.userId]);

  // 스크롤 이벤트 처리기 메소드
  const handleScroll = () => {
    // 스크롤 위치 저장
    let beforeScroll = getScrollY();
    // 헤더의 상단 중 해당 변수만큼 가려줌. top state가 이 값을 따라감
    let heightToHide = 0;

    const maxHeightToHide = bannerHeight + GNB_HEIGHT + exploreHeight;

    return () => {
      const currentScroll = getScrollY();
      const scrollDelta = currentScroll - beforeScroll;
      beforeScroll = currentScroll;

      heightToHide = heightToHide + scrollDelta;
      heightToHide = heightToHide < 0 ? 0 : heightToHide > maxHeightToHide ? maxHeightToHide : heightToHide;

      setTop(-heightToHide);
    };
  };

  useEffect(() => {
    // 스크롤 이벤트 핸들러
    const scrollEvent = handleScroll();
    // 스크롤 이벤트 바인딩
    window.addEventListener('scroll', scrollEvent);
    // unmount 시, scroll 이벤트 제거
    return () => window.removeEventListener('scroll', scrollEvent);
  }, [bannerHeight, isShowExplore]);

  const bandBanners = bandBannerData?.map((data, i) => {
    return <BandBanner key={i} onClickRemove={genRemoveCallbackByBannerId(data.id)} {...data} />;
  });

  return (
    <Wrapper bannerHeight={themeMode === PAGE_TYPE.TOON ? bannerHeight + 122 : bannerHeight + 74}>
      <Container id="gnb" top={themeMode === PAGE_TYPE.TOON ? top : 0}>
        <div id="topBanner">
          <UnsupportedBrowser />
          {hasMounted && Boolean(bandBannerData?.length) && bandBanners}
        </div>
        <Gnb
          wishCount={clips?.length}
          cartCount={cartsCount}
          isCoupon={false}
          isLogin={status === UserInfoStatusEnum.authenticated}
          isLoginLoading={status === UserInfoStatusEnum.loading}
        />
        {themeMode === 'toon' && <SubGnb isSticky={isStickySubGNB} setIsShowExplore={setIsShowExplore} isShowExplore={isShowExplore} />}
      </Container>
    </Wrapper>
  );
};
